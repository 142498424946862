/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyCdDwg9_qlLwuo2OHhgtlqOyvkRwfD2TFY",
  "appId": "1:1023099504601:web:23df2b8f941e3f08453bc8",
  "authDomain": "schooldog-i-schley-ga.firebaseapp.com",
  "measurementId": "G-V1Y28KHVCV",
  "messagingSenderId": "1023099504601",
  "project": "schooldog-i-schley-ga",
  "projectId": "schooldog-i-schley-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-schley-ga.appspot.com"
}
